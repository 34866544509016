.project{
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.project.small{
    justify-content: space-between;
}

.project-header-content h1{
    font-family: Seasons;
    font-size: 210%;
    margin: 10px 0px 0px 0px;
}

.project-header-content h2{
    font-family: Seasons;
    font-size: 130%;
    margin: 10px 0px 0px 0px;
}

.header-description{
    font-size: 150%;
    font-weight: 300;
    margin: 10px 0px 0px 0px;
    line-height: 1.35;
}

.project-header{
    display: grid;
    gap: 50px;
    padding: 0px 50px;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    
}

.project-header-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 0 0 50%; 
    text-align: left;
}

.header-col{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.header-col-parent{
    display: flex;
    gap: 80px;
}

.col{
    font-weight: 300;
    margin: 0px;
}

.project-header a, .cta-col span{
    font-weight: 500;
    font-size: 120%;
    margin-top: 40px;
    text-decoration: none;
    color: var(--dark);
    width: fit-content;
}


.project-header a{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-radius: 36px;
    border: 0px;
    width: fit-content;
}

.project-header a::after{
    content: '';
    display: inline-block;
    width: 8px;
    height: 15px;
    background-image: url('chevron-right.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;
}


.project-header a:hover::after{
    margin-left: 8px;
}

.project-header a:hover.left-marg{
    margin-right: 12px;
}

.full-banner{
    object-fit: cover;
    width: 100%;
    min-height: 400px;
}

.project-header-image{
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    height: fit-content;
}

.crop{
    overflow: hidden;
    height: 300px;
}

.header-main{
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
}



.cta-col{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }



.sonic-overlay {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 7%;
    gap: 5%;
}

.sonic-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.sonic-overlay img{
    width: 100%;
}

.project-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    padding: 0px 50px;
    gap: 100px;
}

.project-content h2{
    font-size: 90%;
    font-weight: 400;
    text-transform: uppercase;
}

.project-content a{
    color: var(--dark);
}

.project-content p{
    font-weight: 200;
    line-height: 1.35;
    font-size: 140%;
}


.figure img{
    width: 100%;
    border-radius: 20px;
}

.project-content .figure{
    border-radius: 40px;
    background-color: var(--hover);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    height: fit-content;
}

.content-video video{
    border-radius: 20px;
}

.project-header a.left-marg{
    margin-top: 0px;
    padding-left: 30px;
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
}


.caption{
    font-size: 100%;
    font-weight: 200;
    text-align: center;
}

@media (max-width: 1020px) {
    .project-header{
        grid-template-columns: 1fr 1fr;
    }
    .full-banner{
        min-height: 300px;
    }

    .header-description{
        font-size: 120%;
    }

    .project-header a{
        font-size: 100%;
    }

    .project-header a img{
        height: 12px;
        padding-left: 8px;
        padding-top: 0px;
    }

    .project-content p{
        font-size: 120%;
    }

    .project-content{
        gap: 40px;
    }
    
}

@media (max-width: 730px) {
    .project{
        padding: 25px 0px 50px 0px;
    }

    .project-header, .project-content{
        grid-template-columns: 1fr;
        padding: 0px 25px;
    }

    .full-banner{
        min-height: 250px;
    }
}
