.projectDisplay{
  display: grid;
  gap: 30px;
  padding: 50px;
  grid-template-columns: repeat(4, 1fr);
}

.homePage, .project{
  height: 100%;
  overflow-y: auto; 
}


.projectCard{
  all: unset;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: auto;
  padding: 30px;
  border-radius: 50px;
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
  justify-content: space-between;
}

.projectCardMain{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.projectCard:hover, .projectCard:focus{
  background-color: var(--hover);
}



.projectCard h1{
  font-family: Seasons;
  font-size: 210%;
  margin: 10px 0px 0px 0px;
}

.projectCard img{
  width: 100%;
  border-radius: 20px;
  height: 240px;
  object-fit: cover;
  background-color: var(--mid);
  transition: height 0.3s ease-in-out;
}



.projectCard p{
  font-weight: 300;
  margin: 10px 0px 0px 0px;
}

.projectCard .tagParent{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 10px 0px 0px 0px;
}

.projectCard .tag{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 200;
  color: var(--mid);
  border-radius: 20px;
  font-size: 70%;
  padding: 0px 10px;
  border: 1px solid var(--mid);
  height: 20px;
  text-transform: uppercase; 
}

.projectCardExtra{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  pointer-events: auto;
  width: fit-content;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  transform: translateY(30px);
  margin-top: 0px;
}

.projectCardExtra::after{
  content: '';
  display: inline-block;
  width: 8px;
  height: 15px;
  background-image: url('chevron-right.svg'); 
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  flex-shrink: 0;
  margin-right: 8px;
  margin-top: 1px;
}

.projectCardExtra:hover:after{
  margin-left: 8px;
  margin-right: 0px;
}

.projectCard:hover .projectCardExtra, .projectCard:focus .projectCardExtra{
  opacity: 1;
  transform: translateY(0px);
  margin-top: 30px;
}

.projectCard:hover img{
  height: 210px;
}


@media (max-width: 1600px) {
  .projectDisplay{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1020px) {
  .projectDisplay{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 720px) {
  .projectDisplay{
    grid-template-columns: repeat(1, 1fr);
    padding: 25px;
  }
}



