#about{
    position: relative;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
}

@keyframes slideUpDown {
    0%, 80% { /* Stay at initial position for most of the cycle */
      margin-top: 0;
      margin-bottom: 14px;
      animation-timing-function: ease-in-out;
    }
    85% { /* First move down */
      margin-top: 14px;
      margin-bottom: 0;
      animation-timing-function: ease-in-out;
    }
    90% { /* Move back up */
      margin-top: 0;
      margin-bottom: 14px;
      animation-timing-function: ease-in-out;
    }
    95% { /* Second move down */
      margin-top: 14px;
      margin-bottom: 0;
      animation-timing-function: ease-in-out;
    }
    100% { /* Return to initial position */
      margin-top: 0;
      margin-bottom: 14px;
    }
  }
  
  .projectButton img {
    animation: slideUpDown 10s infinite;
  }



.projectButton{
    all: unset;
    display: flex;
    height: auto;
    padding: 30px 30px;
    border-radius: 50px;
    transition: background-color 300ms ease-in-out;
    cursor: pointer;
    align-items: center;
    font-size: 400%;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 40px;
    cursor: pointer;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0);
    color: var(--dark);
    transition: margin 0.3s ease-in-out;
}

.projectButton img{
    transform: rotate(90deg) scale(2);
}

.projectButton:hover{
    margin-top: 14px;
    margin-bottom: 26px;
}


.top{
    background-color: var(--dark);
    height: 50%;
    font-weight: 400;
    font-size: 700%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: var(--light);
}

.bottom{
    background-color: var(--light);
    height: 50%;
    font-size: 800%;
    font-family: 'Seasons';
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: var(--dark);
}

#testParent{
    width: 100%;
    position: absolute;
}

.aboutFull{
    height: calc(100vh - 200px);
}

@media (max-width: 730px) {
    .top{
        font-size: 400%;
    }

    .bottom{
        font-size: 500%;
    }


    canvas{
        pointer-events: none;
    }

}