.projectNav{
    display: flex;
    justify-content: space-between;
    margin: 0px 50px;
}

.projectNavLinkRight, .projectNavLinkLeft{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--dark);
    gap: 10px;
    text-decoration: none;
    pointer-events: auto;
    margin-top: 2px;
    text-transform: uppercase;
}

.projectNavLinkRight{
    transform: translateX(20px);
    text-align: right;
}

.projectNavLinkLeft{
    transform: translateX(-20px);
    text-align: left;
}

.projectNavLinkRight::after{
    content: '';
    display: inline-block;
    width: 8px;
    height: 15px;
    background-image: url('chevron-right.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 1px;
}

.projectNavLinkRight:hover:after{
    margin-left: 8px;
    margin-right: 0px;
}


.projectNavLinkLeft::before{
    content: '';
    display: inline-block;
    width: 8px;
    height: 15px;
    background-image: url('chevron-left.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;
    margin-left: 8px;
    margin-top: 1px;
}

.projectNavLinkLeft:hover:before{
    margin-right: 8px;
    margin-left: 0px;
}



@media (max-width: 730px) {
    .projectNav{
        margin: 25px;
    }
}